import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Order } from 'src/app/shared/interfaces/order';
import { OrderProviderService } from 'src/app/shared/providers/order.provider.service';
import { MatRowDef, MatTableDataSource } from '@angular/material/table';
import { SelectOption } from 'src/app/shared/interfaces/select-option';
import { SalesRep } from 'src/app/shared/interfaces/sales-rep';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { StatusInfoDialogComponent } from 'src/app/shared/components/status-info-dialog/status-info-dialog.component';
import { compareOptions } from 'src/app/shared/utils/select.util';
import { Router } from '@angular/router';
import { CustomerSummaryBarService } from 'src/app/shared/modules/customer-summary-bar/services/customer-summary-bar.service';
import { BasicOrder } from 'src/app/shared/interfaces/BasicOrder';
import { UserService } from 'src/app/shared/services/user.service';
import { assert } from 'src/app/shared/utils/assert.util';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';

@Component({
  selector: 'app-order-list-root',
  templateUrl: './order-list-root.component.html',
  styleUrls: ['./order-list-root.component.scss']
})
export class OrderListRootComponent implements OnInit {
  orders: BasicOrder[] = [];
  tableColumns = ['webNumber', 'number', 'date', 'customerNumber', 'customerName', 'repNumber', 'poNumber', 'total', 'status', 'placedBy'];
  dataSource!: MatTableDataSource<BasicOrder>;
  dateRange = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  search: string | null = null;
  selectedStatuses: SelectOption[] | null = null;
  selectedRep: SelectOption | null = null;
  statusOptions: SelectOption[] = [];
  repOptions: SelectOption[] = [];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  isLoading = true;
  selectedTab = 0;

  get shouldShowAllOrders() {
    return this.selectedTab == 0;
  }

  get shouldShowTabBar() {
    return !this.isAR && !this.userService.isRep();
  }

  get isAR() {
    return this.userService.isAR();
  }

  constructor(
    private orderProvider: OrderProviderService,
    private dialog: MatDialog,
    private router: Router,
    private customerSummaryBarService: CustomerSummaryBarService,
    private userService: UserService
  ) { }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.orders = await this.orderProvider.getAllBasic();
    this.dataSource = new MatTableDataSource(this.orders.filter(order => (order.owner === this.userService.getEmail() || this.shouldShowAllOrders)));
    this.dateRange.valueChanges.subscribe(this.dateRangeChanged.bind(this));
    this.loadFilterOptions();
    this.dataSource.paginator = this.paginator;
    this.customerSummaryBarService.delete();
    this.filterDataSource();
    this.sortDataSource();
    this.isLoading = false;
  }

  loadFilterOptions() {
    this.repOptions = this.buildRepFilterOptions(this.dataSource.data.map(order => order.salesRepNumber));
    this.statusOptions = this.buildStatusFilterOptions(this.dataSource.data.map(order => order.status));
    if (this.isAR) {
      const defaultARStatuses = ['AR Hold', 'Pending AR', 'AR Denied', 'Processing', 'Invoiced'];
      this.selectedStatuses = this.statusOptions.filter(option => defaultARStatuses.includes(option.value));
    } else {
      this.selectedStatuses = this.statusOptions;
    }
  }

  buildRepFilterOptions(reps: number[]) {
    const uniqueReps = Array.from(new Set(reps));
    const options: SelectOption[] = uniqueReps.map(repNumber => ({ index: repNumber, value: repNumber.toString() }));
    return options;
  }

  buildStatusFilterOptions(statuses: string[]) {
    const uniqueStatuses = Array.from(new Set(statuses));
    const options: SelectOption[] = uniqueStatuses.map(status => {
      return { index: status, value: status };
    });
    return options;
  }

  searchChanged(value: string) {
    this.search = value;
    this.filterDataSource();
  }

  repChanged(value: SelectOption | null) {
    this.selectedRep = value;
    this.filterDataSource();
  }

  statusChanged(value: [SelectOption] | null) {
    this.selectedStatuses = value;
    this.filterDataSource();
  }

  dateRangeChanged(range: Partial<{ start: Date | null, end: Date | null }>) {
    this.filterDataSource();
  }

  filterDataSource() {
    this.dataSource = new MatTableDataSource(this.orders.filter(order => {
      const isInDateRange = (this.dateRange.value.start === null || this.dateRange.value.end === null) || (this.dateRange.value.start! <= new Date(new Date(order.dateCreated).toDateString()) && new Date(new Date(order.dateCreated).toDateString()) <= this.dateRange.value.end!);
      const doesStatusMatch = this.selectedStatuses === null || this.selectedStatuses.some(status => status.index === order.status);
      const doesMatchRep = this.selectedRep === null || order.salesRepNumber.toString() === this.selectedRep.value;
      const doesContainSearch = (this.search === null || this.search === '') || (order.webNumber.toLocaleLowerCase().includes(this.search.toLowerCase()) || order.number?.toLowerCase().includes(this.search.toLowerCase()) || order.customerNumber?.includes(this.search) || order.customerName.toLowerCase().includes(this.search.toLowerCase()) || order.poNumber?.toLowerCase().includes(this.search.toLowerCase()));
      const shouldShowAllOrders = order.owner === this.userService.getEmail() || this.shouldShowAllOrders;
      return isInDateRange && doesStatusMatch && doesMatchRep && doesContainSearch && shouldShowAllOrders;
    }));
    this.sortDataSource();
    this.dataSource.paginator = this.paginator;
  }

  sortDataSource() {
    this.dataSource.data = this.dataSource.data.sort((a, b) => {
      if (this.getStatusSortPriority(a.status) > this.getStatusSortPriority(b.status)) {
        return 1;
      }

      if (this.getStatusSortPriority(a.status) < this.getStatusSortPriority(b.status)) {
        return -1;
      }

      const aDate = new Date(a.dateCreated);
      const bDate = new Date(b.dateCreated);
      return bDate.getTime() - aDate.getTime();
    });
  }

  getStatusSortPriority(status: string) {
    switch(status) {
      case 'Pending AR':
        return 1;
      case 'AR Hold':
        return 2;
      case 'Pending Retailer':
        return 3;
      case 'Draft':
        return 4;
      case 'Processing':
        return 4;
      case 'Expired':
        return 4;
      case 'Deleted':
        return 4;
      case 'Canceled':
        return 4;
      case 'AR Denied':
        return 4;
      case 'Shipped':
        return 5;
      case 'Delivered':
        return 5;
      default:
        return 6;
    }
  }

  statusClicked(event: Event) {
    event.preventDefault();
    this.dialog.open(StatusInfoDialogComponent);
  }

  orderInfoButtonClicked(event: Event) {
    event.preventDefault();
    this.dialog.open(InfoDialogComponent);
  }

  optionComparator(option: SelectOption | null, value: SelectOption | null) {
    return compareOptions(option, value);
  }

  tableRowClicked(order: Order) {
    this.router.navigateByUrl(`order/view/${order.key}`);
  }

  tabButtonClicked(tab: number) {
    this.selectedTab = tab;
    this.filterDataSource();
  }
}
