import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ArDenyOrderDialogComponent } from 'src/app/shared/components/ar-deny-order-dialog/ar-deny-order-dialog.component';
import { ArHoldDialogComponent } from 'src/app/shared/components/ar-hold-dialog/ar-hold-dialog.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Order } from 'src/app/shared/interfaces/order';
import { ProformaLineItem } from 'src/app/shared/interfaces/proforma-line-item';
import { StockBalanceLineItem } from 'src/app/shared/interfaces/stock-balance-line-item';
import { CustomerSummaryBarService } from 'src/app/shared/modules/customer-summary-bar/services/customer-summary-bar.service';
import { SignatureBoxComponent } from 'src/app/shared/modules/signature-box/components/signature-box/signature-box.component';
import { OrderStatsProviderService } from 'src/app/shared/providers/order-stats.provider.service';
import { OrderProviderService } from 'src/app/shared/providers/order.provider.service';
import { ProformaLineItemProviderService } from 'src/app/shared/providers/proforma-line-item.provider.service';
import { StockBalanceLineItemsProviderService } from 'src/app/shared/providers/stock-balance-line-items.provider.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { SubmitOrderService } from 'src/app/shared/services/submit-order.service';
import { UserService } from 'src/app/shared/services/user.service';
import { assert } from 'src/app/shared/utils/assert.util';
import { buildCollections } from 'src/app/shared/utils/collection.util';
import { getRestrictedCollections } from 'src/app/shared/utils/customer.util';
import { CollectionGroup } from '../../interfaces/collection-group';
import { ApproveCustomerDialogComponent } from 'src/app/shared/components/approve-customer-dialog/approve-customer-dialog.component';
import { environment } from 'src/environments/environment';
import { NetsuiteCustomerProviderService } from 'src/app/shared/providers/netsuite-customer.provider.service';
import { buildNetsuiteContactFromContact, buildNetsuiteCustomerFromCustomer } from 'src/app/shared/utils/netsuite-customer.util';
import { CustomerProviderService } from 'src/app/shared/providers/customer.provider.service';
import { Contact } from 'src/app/shared/interfaces/contact';
import { NewCustomerFormValues } from 'src/app/shared/interfaces/new-customer-form-values';
import { getStatusProgress } from 'src/app/shared/utils/status.util';
import { CustomerSelectDialogResult } from 'src/app/shared/interfaces/customer-select-dialog-result';
import { CustomerSelectDialogComponent } from 'src/app/shared/components/customer-select-dialog/customer-select-dialog.component';
import { SalesRepProvidersService } from 'src/app/shared/providers/sales-rep.provider.service';
import { compareContacts } from 'src/app/shared/utils/contact.util';

@Component({
  selector: 'app-order-view-root',
  templateUrl: './order-view-root.component.html',
  styleUrls: ['./order-view-root.component.scss']
})
export class OrderViewRootComponent implements OnInit {
  order!: Order;
  collectionGroups: CollectionGroup[] = [];
  isLoading = false;
  signature: string | null = null;
  @ViewChild(SignatureBoxComponent) signaturePad!: SignatureBoxComponent;

  get user() {
    return this.userService.get();
  }

  get isOrderOwner() {
    return this.user?.reps.includes(this.order.salesRep.number);
  }

  get canDelete() {
    return this.isOrderOwner &&  this.order.status === 'Draft';
  }

  get canRestore() {
    return (this.isOrderOwner || this.userService.isAR()) && (this.order.status === 'Deleted' || this.order.status === 'Expired');
  }

  get canEdit() {
    const canEditBeofreRetailerApproves = ['Draft', 'Pending Retailer'].includes(this.order.status) && (this.isOrderOwner || this.userService.isAdmin());
    const canEditAfterRetailerApproves = (this.order.status === 'Pending AR' || this.order.status === 'AR Hold') && (this.userService.isAR() || this.userService.isAdmin());
    return canEditBeofreRetailerApproves || canEditAfterRetailerApproves;
  }

  get canChangeCustomer() {
    return (this.order.status === 'Pending AR' || this.order.status === 'AR Hold') && (this.userService.isAR() || this.userService.isAdmin());
  }

  get canConvertToW2W() {
    return (this.order.status === 'Pending AR' || this.order.status === 'AR Hold') && this.order.type === 'Regular' && (this.userService.isAR() || this.userService.isAdmin());
  }

  get canConvertToRegular() {
    return (this.order.status === 'Pending AR' || this.order.status === 'AR Hold') && this.order.type === 'W2W' && (this.userService.isAR() || this.userService.isAdmin());
  }

  get canHold() {
    return this.order.status === 'Pending AR' && (this.userService.isAR() || this.userService.isAdmin());
  }

  get canDenyApprove() {
    return (this.order.status === 'Pending AR' || this.order.status === 'AR Hold') && (this.userService.isAR() || this.userService.isAdmin());
  }

  get canEditHold() {
    return this.userService.isAR() || this.userService.isAdmin();
  }

  get editLink() {
    return `/order/edit/${this.order.key}`;
  }

  get restrictedCollections() {
    return getRestrictedCollections(this.order.customer);
  }


  get canViewNetsuiteNumber() {
    return this.userService.isAdmin() || this.userService.isAdmin();
  }

  get canViewPackingListButton() {
    return (this.order.stockBalance?.lineItems ?? []).length > 0 && getStatusProgress(this.order.status) >= getStatusProgress('Processing');
  }

  get netsuiteCustomerLink() {
    return this.buildNetsuiteCustomerURL(+this.order.customer.netsuiteNumber);
  }

  constructor(
    private orderProvider: OrderProviderService,
    private proformaLineItemProvider: ProformaLineItemProviderService,
    private stockBalanceLineItemProvider: StockBalanceLineItemsProviderService,
    private customerSummaryBarService: CustomerSummaryBarService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private dialog: MatDialog,
    private domSanitizer: DomSanitizer,
    private orderService: OrderService,
    private submitOrderService: SubmitOrderService,
    private orderStatsProvider: OrderStatsProviderService,
    private netsuiteCustomerProvider: NetsuiteCustomerProviderService,
    private customerProvider: CustomerProviderService,
    private repProvider: SalesRepProvidersService
  ) { }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;

    this.orderService.set(null);
    this.submitOrderService.delete();

    const order = await this.getOrder();
    if (order === null) {
      this.router.navigateByUrl('/404')
      return;
    }
    this.order = order;
    this.order.proforma.lineItems = await this.proformaLineItemProvider.get(this.order.proforma.key);

    document.title = `${this.order.number ?? this.order.webNumber} - ${this.order.customer.name} | Sales Rep Order Pad | Benchmark`;

    if (this.order.stockBalance) {
      this.order.stockBalance.lineItems = await this.stockBalanceLineItemProvider.get(this.order.stockBalance.key);
      const Last15MonthsCustomerOrderPerStylePromise = this.orderStatsProvider.GetLast15MonthsOrderPerStyle(this.order.customer.number, 'customer');
      const Last15MonthsStockOrderPerStylePromise = this.orderStatsProvider.GetLast15MonthsOrderPerStyle(this.order.customer.number, 'stock');
      const LastStockOrderedPerSKUPromise = this.orderStatsProvider.GetLastStockOrderedPerSKU(this.order.customer.number);
      Promise.all([Last15MonthsCustomerOrderPerStylePromise, Last15MonthsStockOrderPerStylePromise, LastStockOrderedPerSKUPromise]).then(([Last15MonthsCustomerOrderPerStyle, Last15MonthsStockOrderPerStyle, LastStockOrderedPerSKU]) => {
        this.order.stockBalance?.lineItems.forEach(lineItem => {
        lineItem.areOrderStatsLoaded = true

        const recentStockOrderedCount = Last15MonthsStockOrderPerStyle.find(stat => stat.styleNumber === lineItem.item?.styleNumber) ?? null;
        const recentCustomerOrderedCount = Last15MonthsCustomerOrderPerStyle.find(stat => stat.styleNumber === lineItem.item?.styleNumber) ?? null;
        const lastStockOrdered = LastStockOrderedPerSKU.find(stat => stat.sku === lineItem.item?.sku) ?? null;

        lineItem.lastStockOrdered = lastStockOrdered ? new Date(lastStockOrdered.date) : null;
        lineItem.recentStockOrderedCount = recentStockOrderedCount?.quantity ?? null;
        lineItem.recentCustomerOrderedCount = recentCustomerOrderedCount?.quantity ?? null;
      })});
    }

    this.customerSummaryBarService.set(this.order.customer);

    this.collectionGroups = this.buildCollectionGroups(this.order.proforma.lineItems, this.order.stockBalance?.lineItems ?? []);
    this.isLoading = false;
  }

  private async getOrder() {
    const orderID = this.route.snapshot.params['orderID'];
    if (orderID) {
      const order = await this.orderProvider.get(orderID);
      assert(order, 'Order was null');
      return order;
    }
    const orderViewToken = this.route.snapshot.params['orderViewToken'];
    try {
      const order = await this.orderProvider.getWithViewToken(orderViewToken);
      return order;
    } catch {
      return null;
    }
  }

  private buildCollectionGroups(proformaLineItems: ProformaLineItem[], stockBalanceLineItems: StockBalanceLineItem[]) {
    const proformaCollections = buildCollections(proformaLineItems);
    const stockBalanceCollections = buildCollections(stockBalanceLineItems);

    const collectionNames = stockBalanceCollections.map(collection => collection.name).concat(proformaCollections.map(collection => collection.name));
    const groups: CollectionGroup[] = collectionNames.map(collectionName => ({ proforma: proformaCollections.find(collection => collection.name === collectionName) ?? null, stockBalance: stockBalanceCollections.find(collection => collection.name === collectionName) ?? null }));
    return groups;
  }

  deleteDraft() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { title: 'Remove draft?', body: 'Are you sure you want to remove this draft?', cancelButtonText: 'Cancel', confirmButtonText: 'Remove' } });
    dialogRef.afterClosed().subscribe(async (didConfirm) => {
      if (didConfirm) {
        try {
          await this.orderProvider.delete(this.order.key);
          window.location.reload();
        } catch (e) {
          // handle error
        }
      }
    });
  }

  async restore() {
    try {
      await this.orderProvider.restoreDraft(this.order.key);
      window.location.reload();
    } catch (e) {
      // handle error
    }
  }

  deny() {
    this.dialog.open(ArDenyOrderDialogComponent, { minWidth: 400 }).afterClosed().subscribe(async (reason?: string) => {
      if (reason) {
        this.isLoading = true;
        await this.orderProvider.deny(this.order.key, reason);
        window.location.reload();
      }
    });
  }

  async approve() {
    assert(this.order.customer.billingAddress, 'Customer has no billing address');
    const orderBuyer: Contact = {
      name: this.order.buyerName,
      email: this.order.buyerEmail,
      phone: this.order.buyerPhone,
      country: this.order.customer.billingAddress.country,
      type: 'Buyer'
    };

    const shouldSkipApproveCustomer = !this.order.isOpportunity || this.order.isOpportunity && (this.order.customer.isActive && this.order.customer.netsuiteNumber);
    if (this.order.stockBalance?.lineItems?.some(lineItem => lineItem.isApproved === null || (lineItem.isApproved === false && !lineItem.deniedReason))) {
      this.dialog.open(ConfirmDialogComponent, { maxWidth: 400, data: { body: 'Please complete all "Allow/Deny" and "Denial Reason" fields before continuing.', confirmButtonText: 'Ok' } })
    } else {
      const approvedCustomer: NewCustomerFormValues | null = shouldSkipApproveCustomer ? null : await new Promise((resolve) => {
        this.dialog.open(ApproveCustomerDialogComponent, { data: { customer: this.order.customer, orderBuyer } }).afterClosed().subscribe((result) => {
          resolve(result);
        });
      });

      if (shouldSkipApproveCustomer || approvedCustomer) {
        const approveOrderDialog = this.dialog.open(ConfirmDialogComponent, { data: { title: 'Approve Order?', body: 'Approvals cannot be undone. Are you sure you want to approve this order?', cancelButtonText: 'Cancel', confirmButtonText: 'Approve' } });
        approveOrderDialog.afterClosed().subscribe((shouldApprove) => {
          (async () => {
            try {
              if (shouldApprove) {
                this.isLoading = true;
                let netsuiteCustomerID = +this.order.customer.netsuiteNumber;

                const customerBuyer = this.order.customer.contacts.find(contact => contact.type === 'Buyer');
                const shouldUpdateNetsuiteCustomerBuyer = !compareContacts(customerBuyer, orderBuyer);

                if (this.order.isOpportunity && !shouldSkipApproveCustomer) {
                  assert(approvedCustomer, 'Approved customer is required to submit an opportunity');
                  const netsuiteCustomer = buildNetsuiteCustomerFromCustomer(approvedCustomer);
                  if (netsuiteCustomerID) {
                    await this.netsuiteCustomerProvider.update(netsuiteCustomerID, netsuiteCustomer);
                  } else {
                    netsuiteCustomerID = await this.netsuiteCustomerProvider.create(netsuiteCustomer);
                  }

                  const netsuiteURL = this.buildNetsuiteCustomerURL(netsuiteCustomerID, true);
                  window.open(netsuiteURL, '_blank');
                } else if (shouldUpdateNetsuiteCustomerBuyer) {
                  const contacts = this.order.customer.contacts.filter(contact => contact.type !== 'Buyer').concat(orderBuyer);
                  const netsuiteContacts = contacts.map(buildNetsuiteContactFromContact);
                  await this.netsuiteCustomerProvider.updateContacts(netsuiteCustomerID, netsuiteContacts);
                }

                if (netsuiteCustomerID && netsuiteCustomerID.toString() !== this.order.customer.netsuiteNumber) {
                  await this.customerProvider.updateNetsuiteNumber(this.order.customer.id, netsuiteCustomerID.toString());
                }
                await this.orderProvider.approve(this.order.key);
                window.location.reload();
              }
            } catch (e) {
              console.error(e);
              this.dialog.open(ConfirmDialogComponent, { disableClose: true, maxWidth: 400, data: { cancelButtonText: 'OK', icon: 'error', iconColor: 'critical', title: 'ERROR', body: 'Failed to approved order. Please try again.' } });
            } finally {
              this.isLoading = false;
            }
          })();
        });
      }
    }
  }

  private buildNetsuiteCustomerURL(netsuiteCustomerID: number, edit: boolean = false) {
    return `https://4539549${!environment.production ? '-sb1' : ''}.app.netsuite.com/app/common/entity/custjob.nl?id=${netsuiteCustomerID}${edit ? '&e=T' : ''}`;
  }

  async retailerApprove() {
    this.signaturePad.savePad();
    if (this.signature) {
      this.isLoading = true;
      await this.orderProvider.retailerApprove(this.order.key, this.signature);
      window.location.reload();
    }
  }

  getUnsafeImageURL(url: string) {
    const sanitizedURL = this.domSanitizer.bypassSecurityTrustUrl(url);
    return sanitizedURL;
  }

  showPrintDialog() {
    window.print();
  }

  navigateToPackingList() {
    window.open(`/order/${this.order.key}/packingList`, '_blank');
  }

  hold() {
    this.dialog.open(ArHoldDialogComponent, { minWidth: 400 }).afterClosed().subscribe(async (reason?: string) => {
      if (reason) {
        this.isLoading = true;
        await this.orderProvider.hold(this.order.key, reason);
        window.location.reload();
      }
    });
  }

  async changeCustomer() {
    this.isLoading = true;
    const customers = await this.customerProvider.getAll();
    const repsForOrderOwner = await this.repProvider.getAll(this.order.ownerUsername);
    const filteredCustomers = customers.filter(customer => repsForOrderOwner.map(rep => rep.number).concat([1]).includes(customer.salesRepNumber) );
    this.isLoading = false;
    this.dialog.open(CustomerSelectDialogComponent, { data: filteredCustomers, minWidth: 400 }).afterClosed().subscribe(async (customerResult?: CustomerSelectDialogResult) => {
      if (customerResult) {
        assert(customerResult.address.id, 'Address id was null');
        this.isLoading = true;
        await this.orderProvider.setCustomer(this.order.key, customerResult.customer.id, customerResult.address.id);
        window.location.reload();
      }
    });
  }

  async convertToW2W() {
    this.isLoading = true;
    if (this.areAllLineItemsW2WValid()) {
      try {
        await this.orderProvider.changeType(this.order.key, 'W2W');
      } catch(e) {
        console.error(e);
        this.dialog.open(ConfirmDialogComponent, { disableClose: true, maxWidth: 400, data: { cancelButtonText: 'OK', icon: 'error', iconColor: 'critical', title: 'ERROR', body: 'Failed to convert order. Please try again.' } });
      }
      window.location.reload();
    } else {
      this.dialog.open(ConfirmDialogComponent, { disableClose: true, maxWidth: 400, data: { cancelButtonText: 'OK', title: 'Unable to Convert Order', body: 'To convert this order, all line items must be classified as Plain or Carved. Please remove any line items not in either of the previously mentioned classes.' } });
      this.isLoading = false;
    }
  }

  private areAllLineItemsW2WValid() {
    return this.order.proforma.lineItems.every(lineItem => lineItem.item?.class.isW2WEligible);
  }

  async convertToRegular() {
    this.isLoading = true;
    try {
      await this.orderProvider.changeType(this.order.key, 'Regular');
    } catch(e) {
      console.error(e);
      this.dialog.open(ConfirmDialogComponent, { disableClose: true, maxWidth: 400, data: { cancelButtonText: 'OK', icon: 'error', iconColor: 'critical', title: 'ERROR', body: 'Failed to convert order. Please try again.' } });
    }
    window.location.reload();
  }
}
