import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { User } from '../interfaces/user';
import { JWTUserMapper } from '../models/jw-user-mapper/jwt-user-mapper';
import { assert } from '../utils/assert.util';
import { SubscribableService } from './subscribable.service';
import { LoginProviderService } from '../providers/login.provider.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private get currentUser() {
    return this.jwtUserMapper.get();
  }

  constructor(
    private jwtUserMapper: JWTUserMapper,
    private cookieService: CookieService,
    private loginProvider: LoginProviderService
    ) {  }

  get(): User | null {
    return this.jwtUserMapper.get();
  }

  getEmail(): string | null {
    return this.jwtUserMapper.get()?.email ?? null;
  }

  getRepNumber(): number | null {
    const [repNumber] = this.jwtUserMapper.get()?.reps ?? [];
    return repNumber ?? null;
  }

  getRepNumbers(): number[] {
    return this.jwtUserMapper.get()?.reps ?? [];
  }

  async logout() {
    const success = await this.loginProvider.logout();

    if (environment.production || environment.type === 'staging') {
      this.cookieService.deleteAll('/', '.benchmarkrings.com');
    } else {
      this.cookieService.deleteAll('jwtBearerToken');
    }

    if (!success) {
      throw new Error('Could not logout');
    }
  }

  isAdmin() {
    return this.matchRole('admin');
  }

  isAR() {
    return this.matchRole('ar');
  }

  isManager() {
    return this.matchRole('manager');
  }

  isRep() {
    return this.matchRole('rep');
  }

  private matchRole(role: string) {
    return this.currentUser?.role.toLowerCase() === role.toLowerCase();
  }

  hasRole() {
    return !!this.currentUser?.role;
  }

  getKeyPrefix() {
    const email = this.currentUser?.email;
    assert(email, 'User or username was null.');
    const usernameWithoutDomain = email.split('@').shift();
    assert(usernameWithoutDomain, 'Could not get username without domain.');
    const prefixArray = Array.from(usernameWithoutDomain).flatMap(char => char.charCodeAt(0));
    const prefix = prefixArray.join('');
    return prefix;
  }
}
